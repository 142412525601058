import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.show,"width":"440","persistent":""}},[_c(VCard,[_c('div',{attrs:{"align":"right"}},[_c(VBtn,{staticClass:"pr-2 pt-2",attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"px-5"},[_c('div',{staticClass:"pt-5 pb-8",attrs:{"align":"center"}},[_c('strong',[_vm._v("Stake Onboarding")])]),_c('div',{staticClass:"pb-5 px-5"},[_c('ol',[_c('li',{staticClass:"pb-5"},[_vm._v(" You need to stake DBIO to activate your account. There’s no locking period. Your fund can be unstaked anytime with your consent but there will be a period of 6 days to process it. ")]),_c('li',{staticClass:"pb-5"},[_vm._v(" By Unstaking your fund, you will be an inactive users. ")]),_c('li',{staticClass:"pb-5"},[_vm._v(" On the condition that your verification is rejected, your fund will be refunded. ")])])]),_c('div',{staticClass:"d-flex justify-space-between mb-3"},[_c('div',[_c('strong',[_vm._v("Staking Amount")])]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.stakeAmount)+" DBIO")])])]),(!_vm.computeBalance)?_c('div',{staticClass:"mb-3",staticStyle:{"color":"#F5222D"}},[_c('strong',[_vm._v("Insufficient Balance")])]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"mb-5"},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Estimated Transaction Weight ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","size":"14"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("Total fee paid in DBIO to execute this transaction.")])])],1),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(Number(_vm.fee).toFixed(4))+" DBIO ")])]),_c('Button',{staticClass:"mb-5",style:(!_vm.computeBalance ? 'background-color: #c7c7c7 !important; cursor: default;' : ''),attrs:{"elevation":"2","block":"","loading":_vm.loading,"disabled":!_vm.computeBalance},on:{"click":_vm.handleSubmit}},[_vm._v(" Stake ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }