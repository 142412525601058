import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import { uploadFile, getFileUrl } from "@/lib/pinata-proxy";
import { createService, updateService, createServiceFee, updateServiceFee } from "@/lib/polkadotProvider/command/services";
import { getCategories, getConversionCache, getDNACollectionProcess } from "@/lib/api";
import List from "./List";
import Stepper from "../Stepper";
import { stakeLab } from "@/lib/polkadotProvider/command/labs";
import DialogAlert from "@/components/Dialog/DialogAlert";
import DialogStake from "@/components/Dialog/DialogStake";
import serviceHandler from "@/mixins/serviceHandler";
import { toEther, formatUSDTE } from "@/lib/balance-format";
import { sendEmailRegisteredLab } from "@/lib/api/lab";
import rulesHandler from "@/constants/rules";
import { generalDebounce } from "@/utils";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
export default {
  name: "LabRegistrationServices",
  mixins: [serviceHandler],
  components: {
    List,
    Stepper,
    DialogAlert,
    DialogErrorBalance,
    DialogStake
  },
  data: () => ({
    serviceId: "",
    usdRate: 0,
    document: {
      category: "",
      dnaCollectionProcess: "",
      name: "",
      currency: "USDT.e",
      price: 0,
      qcPrice: 0,
      description: "",
      longDescription: "",
      duration: "",
      durationType: "Days",
      linkKit: null
    },
    kitPurchaseLink: null,
    imageUrl: "",
    testResultSampleUrl: "",
    files: [],
    testResultSampleFile: [],
    listCategories: [],
    sampleFiles: [],
    isLoading: false,
    isSubmiting: false,
    isUploading: false,
    currencyList: ["USDT.e"],
    listExpectedDuration: [{
      text: "Hours",
      value: "Hours"
    }, {
      text: "Days",
      value: "Days"
    }],
    isEdit: false,
    stepperItems: [{
      name: "Lab Information",
      selected: true
    }, {
      name: "Lab Services",
      selected: false
    }],
    dialogAlert: false,
    dnaCollectionProcessList: [],
    isBiomedical: false,
    fee: 0,
    isShowError: false,
    dialogStake: false,
    stakeLoading: false
  }),
  async created() {
    this.dnaCollectionProcessList = (await getDNACollectionProcess()).data;
    await this.getServiceCategory();
    this.usdRate = await getConversionCache(this.document.currency === "USDT.e" ? "USDT" : this.document.currency, "USD");
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      web3: state => state.metamask.web3,
      isLabExist: state => state.substrate.isLabAccountExist,
      isServicesExist: state => state.substrate.isServicesExist,
      lastBlockData: state => state.substrate.lastBlockData
    }),
    ...mapGetters({
      pair: "substrate/wallet"
    }),
    priceHint() {
      return "".concat(this.document.price, " ").concat(this.document.currency, " = ").concat((this.usdRate.conversion * this.document.price).toFixed(4), " USD");
    },
    qcPriceHint() {
      return "".concat(this.document.qcPrice, " ").concat(this.document.currency, " = ").concat((this.usdRate.conversion * this.document.qcPrice).toFixed(4), " USD");
    },
    fieldRequiredRule() {
      return [rulesHandler.FIELD_REQUIRED];
    },
    linkRules() {
      return [val => /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,7}\b([-a-zA-Z0-9()@:%_.~#?&/=]*)/.test(val) || "Link is invalid"];
    },
    serviceNameRules() {
      return [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(50)];
    },
    priceRules() {
      return [rulesHandler.FIELD_REQUIRED, val => val && val != 0 || "Value on this field cannot 0", val => /^\d*(\.\d{0,4})?$/.test(val) || this.isBiomedical || "This field only allows 4 decimal characters."];
    },
    cqPriceRules() {
      return [rulesHandler.FIELD_REQUIRED, val => /^\d*(\.\d{0,4})?$/.test(val) || this.isBiomedical || "This field only allows 4 decimal characters."];
    },
    descriptionRules() {
      return [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(100)];
    },
    longDescriptionRules() {
      return [rulesHandler.FIELD_REQUIRED];
    },
    expectedDurationRules() {
      return [rulesHandler.FIELD_REQUIRED, val => val && val != 0 || "Value on this field cannot 0"];
    },
    fileInputRules() {
      return [value => !Array.isArray(value) || "This field is required", value => !!value && value.size < 2000000 || "The total file size uploaded exceeds the maximum file size allowed (2MB)", value => !!value && value.type === "application/pdf" || "The files uploaded are not in the supported file formats."];
    }
  },
  watch: {
    document: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        await this.getFee();
      }, 500)
    }
  },
  methods: {
    async getServiceCategory() {
      const {
        data: data
      } = await getCategories();
      this.listCategories = data;
    },
    setDeleteLoading(isLoading) {
      this.isLoading = isLoading;
      if (!this.isServicesExist) {
        this.stepperItems = [{
          name: "Lab Information",
          selected: true
        }, {
          name: "Lab Services",
          selected: false
        }];
      }
    },
    async gotoDashboard() {
      await this.$store.dispatch("substrate/getLabAccount");
      this.$router.push({
        name: "lab-dashboard"
      });
    },
    async handleStakeLab() {
      this.isSubmiting = true;
      this.stakeLoading = true;
      try {
        await stakeLab(this.api, this.pair);
        const {
          labAccount
        } = await this.$store.dispatch("substrate/getLabAccount");
        if (this.isLabExist && labAccount.verificationStatus === "Unverified") {
          var _this$lastBlockData;
          await sendEmailRegisteredLab(this.wallet.address);
          this.$store.dispatch("substrate/addAnyNotification", {
            address: this.wallet.address,
            dataAdd: {
              message: "You've successfully submitted your account verification.",
              data: labAccount,
              route: null,
              params: null
            },
            block: (_this$lastBlockData = this.lastBlockData) === null || _this$lastBlockData === void 0 ? void 0 : _this$lastBlockData.block.header.number,
            role: "lab"
          });
        }
        this.dialogAlert = true;
      } catch (error) {
        console.error(error);
      }
      this.isSubmiting = false;
      this.stakeLoading = false;
    },
    setServices() {
      this.$store.state.substrate.isServicesExist = true;
    },
    clearServicesForm() {
      this.$refs.serviceForm.resetValidation();
      this.document = {
        category: "",
        dnaCollectionProcess: "",
        name: "",
        currency: "USDT.e",
        price: 0,
        qcPrice: 0,
        description: "",
        longDescription: null,
        duration: "",
        durationType: "Days",
        linkKit: null,
        kitPurchaseLink: null
      }, this.testResultSampleFile = "";
      this.imageUrl = "";
      this.fee = 0;
    },
    async prefillServicesForm(service) {
      const hexCheck = /^[0-9a-fA-F]+$/;
      try {
        window.scrollTo({
          top: 100,
          behavior: "smooth"
        });
        const formatPrice = (price, currency) => {
          return this.web3.utils.fromWei(String(price.replaceAll(",", "")), currency === "USDT" || currency === "USDTE" || currency === "USDT.e" ? "mwei" : "ether");
        };
        const {
          category,
          description,
          dnaCollectionProcess,
          expectedDuration,
          image,
          longDescription,
          name,
          pricesByCurrency,
          testResultSample
        } = service.info;
        this.serviceId = service.id;
        this.document = {
          category,
          dnaCollectionProcess,
          name,
          description,
          longDescription: hexCheck.test(longDescription) ? this.web3.utils.hexToUtf8(longDescription) : longDescription,
          currency: formatUSDTE(pricesByCurrency[0].currency),
          price: formatPrice(pricesByCurrency[0].priceComponents[0].value, pricesByCurrency[0].currency),
          qcPrice: formatPrice(pricesByCurrency[0].additionalPrices[0].value, pricesByCurrency[0].currency),
          duration: expectedDuration.duration,
          durationType: expectedDuration.durationType
        };
        this.testResultSampleUrl = testResultSample;
        this.imageUrl = image;
        const res = await fetch(testResultSample);
        const blob = await res.blob(); // Gets the response and returns it as a blob
        const file = new File([blob], "".concat(name, " Test result sample"), {
          type: "application/pdf"
        });
        this.testResultSampleFile = file;
        this.isEdit = true;
      } catch (err) {
        console.error(err);
      }
    },
    async triggerCreateOrUpdate() {
      if (this.isLoading) return; // If function already running return.
      if (!this.$refs.serviceForm.validate()) {
        return;
      }
      this.isLoading = true;
      if (this.isEdit) {
        await this.updateService();
        this.isEdit = false;
        return;
      }
      await this.handleCreateService();
    },
    async getFee() {
      const {
        category,
        dnaCollectionProcess,
        name,
        currency,
        price,
        qcPrice,
        description,
        longDescription,
        duration,
        durationType
      } = this.document;
      const newService = {
        name,
        pricesByCurrency: [{
          currency: formatUSDTE(currency),
          totalPrice: await toEther(price + qcPrice, currency),
          priceComponents: [{
            component: "testing_price",
            value: await toEther(price, currency)
          }],
          additionalPrices: [{
            component: "qc_price",
            value: await toEther(qcPrice, currency)
          }]
        }],
        expectedDuration: {
          duration,
          durationType
        },
        category,
        description,
        longDescription,
        dnaCollectionProcess,
        image: this.imageUrl,
        testResultSample: this.testResultSampleUrl
      };
      let fee;
      if (this.isEdit) {
        fee = await updateServiceFee(this.api, this.wallet, this.serviceId, newService);
        this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
        return;
      }
      fee = await createServiceFee(this.api, this.wallet, newService);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    async handleCreateService() {
      const flow = "RequestTest";
      const {
        category,
        dnaCollectionProcess,
        name,
        currency,
        price,
        qcPrice,
        description,
        longDescription,
        duration,
        durationType
      } = this.document;
      const newService = {
        name,
        pricesByCurrency: [{
          currency: formatUSDTE(currency),
          totalPrice: await toEther(price + qcPrice, currency),
          priceComponents: [{
            component: "testing_price",
            value: await toEther(price, currency)
          }],
          additionalPrices: [{
            component: "qc_price",
            value: await toEther(qcPrice, currency)
          }]
        }],
        expectedDuration: {
          duration,
          durationType
        },
        category,
        description,
        longDescription: this.web3.utils.utf8ToHex(this.document.linkKit === "yes" ? longDescription + "||" + this.kitPurchaseLink : longDescription),
        dnaCollectionProcess,
        image: this.imageUrl,
        testResultSample: this.testResultSampleUrl
      };
      try {
        await this.dispatch(createService, this.api, this.wallet, newService, flow, () => {
          this.setServices();
          this.clearServicesForm();
          this.isLoading = false;
          this.stepperItems = [{
            name: "Lab Information",
            selected: true
          }, {
            name: "Lab Services",
            selected: true
          }];
        });
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    closeDialog() {
      this.isShowError = false;
    },
    async updateService() {
      const {
        category,
        dnaCollectionProcess,
        name,
        currency,
        price,
        qcPrice,
        description,
        longDescription,
        duration,
        durationType
      } = this.document;
      const newService = {
        name,
        pricesByCurrency: [{
          currency: formatUSDTE(currency),
          totalPrice: await toEther(price + qcPrice),
          priceComponents: [{
            component: "testing_price",
            value: await toEther(price, currency)
          }],
          additionalPrices: [{
            component: "qc_price",
            value: await toEther(qcPrice, currency)
          }]
        }],
        expectedDuration: {
          duration,
          durationType
        },
        category,
        description,
        longDescription: this.web3.utils.utf8ToHex(this.document.linkKit === "yes" ? longDescription + "||" + this.kitPurchaseLink : longDescription),
        dnaCollectionProcess,
        image: this.imageUrl,
        testResultSample: this.testResultSampleUrl
      };
      await this.dispatch(updateService, this.api, this.wallet, this.serviceId, newService, () => {
        this.clearServicesForm();
        this.isLoading = false;
      });
    },
    async imageUploadEventListener(file) {
      if (!file || file.length === 0) {
        return;
      }
      this.isUploading = true;
      this.isLoading = true;
      this.imageUrl = "";
      if (file) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.imageUrl = link;
        this.isUploading = false;
        this.isLoading = false;
      }
    },
    async fileUploadEventListener(file) {
      if (!file || file.size >= 2000000 || file.length === 0) {
        return;
      }
      this.isUploading = true;
      this.isLoading = true;
      this.testResultSampleUrl = "";
      if (file) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.testResultSampleUrl = link;
        this.isUploading = false;
        this.isLoading = false;
      }
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    selectPicture() {
      this.$refs.fileInput.$refs.input.click();
    }
  }
};