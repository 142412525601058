import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import Button from "@/components/Button";
import { stakeLabFee } from "@/lib/polkadotProvider/command/labs";
import { queryBalance } from "@/lib/polkadotProvider/query/balance";
import { minimumStakeAmount } from "@/lib/polkadotProvider/query/labs";
import { toEther } from "@/lib/balance-format";
export default {
  name: "DialogStake",
  components: {
    Button
  },
  computed: {
    ...mapState({
      web3: state => state.metamask.web3,
      wallet: state => state.substrate.wallet
    }),
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet"
    }),
    computeBalance() {
      return this.balance > this.minimumStake;
    }
  },
  props: {
    show: Boolean,
    loading: Boolean
  },
  data: () => ({
    fee: 0,
    balance: 0,
    minimumStake: 0,
    stakeAmount: ""
  }),
  async mounted() {
    await this.getStakeFee();
    await this.fetchWalletBalance();
    await this.getMinimumStake();
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit("submit");
    },
    async getMinimumStake() {
      const stakeAmount = await minimumStakeAmount(this.api);
      const _minimumStakeAmount = Number(stakeAmount.replaceAll(",", ""));
      this.minimumStake = stakeAmount ? _minimumStakeAmount / 10 ** 18 : toEther(50000, "DBIO");
      this.stakeAmount = new Intl.NumberFormat("en-US").format(_minimumStakeAmount / 10 ** 18);
    },
    async fetchWalletBalance() {
      try {
        const balance = await queryBalance(this.api, this.wallet.address);
        this.balance = balance;
      } catch (err) {
        console.error(err);
      }
    },
    async getStakeFee() {
      try {
        const fee = await stakeLabFee(this.api, this.pair);
        this.fee = Number(this.web3.utils.fromWei(String(fee.partialFee), "ether"));
      } catch (error) {
        console.error(error);
      }
    }
  }
};