import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import serviceHandler from "@/mixins/serviceHandler";
import { deleteService, deleteServiceFee } from "@/lib/polkadotProvider/command/services";
import DialogDelete from "@/components/Dialog/DialogDeleteConfirmation";
import { getIpfsMetaData } from "@/lib/pinata-proxy";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
export default {
  name: "Service",
  mixins: [serviceHandler],
  components: {
    DialogErrorBalance,
    DialogDelete
  },
  data: () => ({
    deleteConfirmation: false,
    service: null,
    services: null,
    isShowError: false,
    fee: 0
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      labAccount: "substrate/labAccount",
      isLabAccountExist: "substrate/isLabAccountExist"
    }),
    ...mapState({
      web3: state => state.metamask.web3
    })
  },
  watch: {
    labAccount: {
      deep: true,
      immediate: true,
      handler: async function (value) {
        const servicesTmp = [];
        if (!(value !== null && value !== void 0 && value.services)) return;
        for (const service of value.services) {
          const {
            rows
          } = await getIpfsMetaData(service.info.testResultSample.split("/").pop());
          servicesTmp.push({
            ...service,
            documentName: rows[0].metadata.name
          });
        }
        this.services = servicesTmp;
      }
    }
  },
  methods: {
    addService() {
      this.$emit("add-service");
    },
    editService(service) {
      this.$emit("edit-service", service);
    },
    handleOpenResultSample(link) {
      const a = document.createElement("a");
      a.target = "_blank";
      a.rel = "noreferrer noopener nofollow";
      a.href = link;
      a.click();
    },
    async toggleDelete(service) {
      if (service) {
        this.service = service;
        this.deleteConfirmation = true;
      } else {
        this.service = null;
        this.deleteConfirmation = false;
      }
      await this.getDeleteFee();
    },
    async getDeleteFee() {
      const fee = await deleteServiceFee(this.api, this.pair, this.service.id);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    closeDialog() {
      this.isShowError = false;
    },
    async deleteService() {
      const service = this.service;
      try {
        this.$emit("delete-service", true);
        await this.dispatch(deleteService, this.api, this.pair, service.id, () => {
          this.isLoading = false;
          this.deleteConfirmation = false;
          if (this.labAccount.services.length == 0) {
            this.$store.state.substrate.isServicesExist = false;
            this.$emit("delete-service", false);
          }
        });
        this.$emit("delete-service", false);
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    priceByCurrency(amount, currency) {
      let price = this.web3.utils.fromWei(amount, currency === "USDT" || currency === "USDTE" || currency === "USDT.e" ? "mwei" : "ether");
      return "".concat(price, " ").concat(currency);
    }
  }
};