import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.show,"width":"500","persistent":""}},[_c(VCard,[_c('div',{staticClass:"d-flex justify-center pb-5 pt-5"},[_c(VImg,{attrs:{"src":require('../../assets/alert-circle.png'),"max-width":67}})],1),_c('div',{staticClass:"pb-5",attrs:{"align":"center"}},[_c('strong',[_vm._v("Are you sure you want delete this "+_vm._s(_vm.type)+"?")]),_c('div',{staticClass:"d-flex justify-space-between mt-3 px-16"},[_c('div',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Estimated Transaction Weight ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","size":"14"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("Total fee paid in DBIO to execute this transaction.")])])],1),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(Number(_vm.fee).toFixed(4))+" DBIO ")])])]),_c('div',{staticClass:"d-flex justify-center pb-5"},[_c(VCol,{attrs:{"lg":"5","md":"5","sm":"8"}},[_c('Button',{attrs:{"elevation":"2","dark":"","color":"secondary"},on:{"click":_vm.closeDialog}},[_vm._v(" No ")])],1),_c(VCol,{attrs:{"lg":"5","md":"5","sm":"8"}},[_c('Button',{attrs:{"elevation":"2","dark":"","color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" Yes ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }